<template>
  <section class="head">
    <h1>{{ examInfo.examName }}</h1>
    <div class="head-info">
      <div class="signature" v-if="paperData.signImage">
        <img :src="paperData.signImage" />
      </div>
      <div class="info">
        <span>总分：{{ examInfo.totalScore }}</span>
        <span>及格线：{{ examInfo.passScore }}</span>
        <span>
          剩余次数：
          <template v-if="examInfo.examTimes > 1">
            {{ examInfo.examTimes - examInfo.myTimes }}
          </template>
          <template v-else-if="examInfo.examTimes == -1">0</template>
          <template v-else-if="examInfo.examTimes == -2">不限</template>
        </span>
        <span>
          总时长：
          {{
            examInfo.limitTime != 0 ? examInfo.limitTime / 60 + "分钟" : "不限"
          }}
        </span>
        <span>若有主观题，系统不支持自动判分</span>
      </div>
    </div>
  </section>

  <section class="statistics">
    <div class="content">
      <div class="item">
        <div class="progress loadding" v-if="paperData.status == 1">
          <a-progress
            type="dashboard"
            :width="134"
            :strokeWidth="2"
            :showInfo="true"
            :gapDegree="150"
            strokeColor="#FFFC21"
            trailColor="#006D05"
            :percent="(paperData.score / examInfo.totalScore) * 100"
          >
            <template #format>
              <div class="progress-info">
                <div class="symbol">
                  <SmallDashOutlined />
                </div>
                <div class="score"><strong>待批阅</strong></div>
                <div class="text">等待中</div>
              </div>
            </template>
          </a-progress>
        </div>
        <div class="progress" v-else-if="paperData.score >= examInfo.passScore">
          <a-progress
            type="dashboard"
            :width="134"
            :strokeWidth="2"
            :showInfo="true"
            :gapDegree="150"
            strokeColor="#66FF7C"
            trailColor="#0C4FD3"
            :percent="(paperData.score / examInfo.totalScore) * 100"
          >
            <template #format>
              <div class="progress-info">
                <div class="symbol">
                  <CheckOutlined />
                </div>
                <div class="score">
                  <strong>{{ paperData.score }}</strong
                  >分
                </div>
                <div class="text">已通过</div>
              </div>
            </template>
          </a-progress>
        </div>
        <div class="progress fail" v-else>
          <a-progress
            type="dashboard"
            :width="134"
            :strokeWidth="2"
            :showInfo="true"
            :gapDegree="150"
            strokeColor="#FFFC21"
            trailColor="#A60C0C"
            :percent="(paperData.score / examInfo.totalScore) * 100"
          >
            <template #format>
              <div class="progress-info">
                <div class="symbol">
                  <CloseOutlined />
                </div>
                <div class="score">
                  <strong>{{ paperData.score }}</strong
                  >分
                </div>
                <div class="text">不通过</div>
              </div>
            </template>
          </a-progress>
        </div>
      </div>
      <div class="item">
        <div class="num">
          <strong>{{ rightNum }}</strong>
          <i>道</i>
        </div>
        <div class="label">正确</div>
      </div>
      <div class="item">
        <div class="num">
          <strong v-if="qusetionTotal">{{ qusetionTotal - rightNum }}</strong>
          <i>道</i>
        </div>
        <div class="label">错误</div>
      </div>
      <div class="item">
        <div class="num">
          <strong v-if="paperData.questions">
            {{ Number.parseInt((rightNum / qusetionTotal) * 100) }}
          </strong>
          <i>%</i>
        </div>
        <div class="label">准确率</div>
      </div>
      <div class="item">
        <div class="num">
          <template v-if="paperData.useTime > 0">
            <strong v-html="formatSeconds(paperData.useTime, true)"></strong>
          </template>
          <strong v-else> 0<span>秒</span> </strong>
        </div>
        <div class="label">所用时长</div>
      </div>
    </div>
  </section>

  <section class="main">
    <div class="content">
      <div class="questions">
        <div
          class="type-path"
          v-for="(path, index) in quesObj.values()"
          :key="index"
        >
          <template v-if="path.data.length && path.name !== '案例题'">
            <div class="headline">
              <span class="classify">
                {{ toChinesNum(index + 1) }}、{{ path.name }}
              </span>
              <span class="score">
                （本大题共{{ path.data.length }}小题， 总分
                {{ path.totalScore }}分）
              </span>
            </div>
            <div
              class="questions-item"
              v-for="(item, queIndex) in path.data"
              :key="item.questionId"
              :id="'que_' + item.questionId"
            >
              <div class="questions-item-head">
                <div class="type-title">
                  {{ queIndex + 1 }}.{{ path.name }}
                  <span>（{{ item.score }}分）</span>
                </div>
              </div>
              <template v-if="path.name === '单选题' || path.name === '判断题'">
                <p class="title">{{ item.title }}</p>
                <template v-if="item.titimgs && item.titimgs.length">
                  <div
                    class="tit-media-wrap"
                    v-for="(t, tIndex) in item.titimgs"
                    :key="tIndex"
                  >
                    <a-image v-if="getResourceType(t) == 'image'" :src="t" />
                    <video
                      controlslist="nodownload"
                      v-else-if="getResourceType(t) == 'video'"
                      :src="t"
                      controls
                    />
                    <audio
                      controlslist="nodownload"
                      v-else-if="getResourceType(t) == 'audio'"
                      :src="t"
                      controls
                    />
                  </div>
                </template>
                <div
                  class="opt-radio"
                  :class="{
                    active: opt.isRight,
                    right: item.answers.includes(opt.id),
                  }"
                  v-for="(opt, optIndex) in item.options"
                  :key="opt.id"
                >
                  <div class="opt-item">
                    <span class="letter">{{ letterOpts[optIndex] }}</span>
                    <span class="text">{{ opt.title }}</span>
                  </div>
                  <div class="opt-media-wrap" v-if="opt.img">
                    <a-image
                      v-if="getResourceType(opt.img) == 'image'"
                      :src="opt.img"
                    />
                    <video
                      controlslist="nodownload"
                      v-else-if="getResourceType(opt.img) == 'video'"
                      :src="opt.img"
                      controls
                    />
                    <audio
                      controlslist="nodownload"
                      v-else-if="getResourceType(opt.img) == 'audio'"
                      :src="opt.img"
                      controls
                    />
                  </div>
                </div>
                <div class="answer">
                  <div class="symbol">
                    <CheckSquareFilled class="right" v-if="item.myScore > 0" />
                    <CloseSquareFilled class="wrong" v-else />
                  </div>
                  <div class="info">
                    <p>
                      <span class="right" style="padding-right: 10px">
                        <b>正确答案：</b>
                        <i>{{ letterOpts[item.correctAnswer] }}</i>
                      </span>
                      <span
                        :class="item.myScore > 0 ? 'right' : 'wrong'"
                        style="padding-right: 10px"
                      >
                        <b>我的答案：</b>
                        <i>{{ letterOpts[item.userAnswer] }}</i>
                      </span>
                      <span :class="item.myScore > 0 ? 'right' : 'wrong'">
                        <b>我的得分：</b>
                        <i>{{ item.myScore }}</i>
                      </span>
                    </p>
                    <p v-if="item.analysis">
                      <b>答题解析：</b>{{ item.analysis }}
                    </p>
                  </div>
                </div>
              </template>
              <template v-else-if="path.name === '多选题'">
                <p class="title">{{ item.title }}</p>
                <template v-if="item.titimgs && item.titimgs.length">
                  <div
                    class="tit-media-wrap"
                    v-for="(t, tIndex) in item.titimgs"
                    :key="tIndex"
                  >
                    <a-image v-if="getResourceType(t) == 'image'" :src="t" />
                    <video
                      controlslist="nodownload"
                      v-else-if="getResourceType(t) == 'video'"
                      :src="t"
                      controls
                    />
                    <audio
                      controlslist="nodownload"
                      v-else-if="getResourceType(t) == 'audio'"
                      :src="t"
                      controls
                    />
                  </div>
                </template>
                <div>
                  <div
                    class="opt-checkbox"
                    :class="{
                      active: opt.isRight,
                      right: item.answers.includes(opt.id),
                    }"
                    v-for="(opt, optIndex) in item.options"
                    :key="opt.id"
                  >
                    <div class="opt-item">
                      <span
                        class="letter"
                        :class="{
                          wrong: !opt.isRight && item.answers.includes(opt.id),
                        }"
                      >
                        {{ letterOpts[optIndex] }}
                      </span>
                      <span
                        class="text"
                        :class="{
                          wrong: opt.isRight && !item.answers.includes(opt.id),
                        }"
                      >
                        {{ opt.title }}
                      </span>
                    </div>
                    <div class="opt-media-wrap" v-if="opt.img">
                      <a-image
                        v-if="getResourceType(opt.img) == 'image'"
                        :src="opt.img"
                      />
                      <video
                        controlslist="nodownload"
                        v-else-if="getResourceType(opt.img) == 'video'"
                        :src="opt.img"
                        controls
                      />
                      <audio
                        controlslist="nodownload"
                        v-else-if="getResourceType(opt.img) == 'audio'"
                        :src="opt.img"
                        controls
                      />
                    </div>
                  </div>
                </div>
                <div class="answer">
                  <div class="symbol">
                    <CheckSquareFilled class="right" v-if="item.myScore > 0" />
                    <CloseSquareFilled class="wrong" v-else />
                  </div>
                  <div class="info">
                    <p>
                      <span class="right" style="padding-right: 10px">
                        <b>正确答案：</b>
                        <i v-for="sele in item.correctAnswer" :key="sele">
                          {{ letterOpts[sele] }}
                        </i>
                      </span>
                      <span
                        :class="item.myScore > 0 ? 'right' : 'wrong'"
                        style="padding-right: 10px"
                      >
                        <b>我的答案：</b>
                        <i v-for="sele in item.userAnswer" :key="sele">
                          {{ letterOpts[sele] }}
                        </i>
                      </span>
                      <span :class="item.myScore > 0 ? 'right' : 'wrong'">
                        <b>我的得分：</b>
                        <i>{{ item.myScore }}</i>
                      </span>
                    </p>
                    <p v-if="item.analysis">
                      <b>答题解析：</b>{{ item.analysis }}
                    </p>
                  </div>
                </div>
              </template>
              <template v-else-if="path.name === '填空题'">
                <p class="title">
                  <template
                    v-for="(opt, optIndex) in item.options"
                    :key="opt.id"
                  >
                    <span>{{ item.titles[optIndex] || "、" }}</span>
                    <span class="opt-input">
                      {{ opt.title }}
                      <b v-if="item.strAnswer">
                        <span>【</span>
                        <i
                          v-for="(text, textIndex) in JSON.parse(
                            item.strAnswer
                          )[optIndex].split('$')"
                          :key="textIndex"
                        >
                          {{ text }}
                        </i>
                        <span>】</span>
                      </b>
                    </span>
                  </template>
                  <span v-if="item.titles.length > item.options.length">
                    {{ item.titles[item.titles.length - 1] }}
                  </span>
                </p>
                <template v-if="item.titimgs && item.titimgs.length">
                  <div
                    class="tit-media-wrap"
                    v-for="(t, tIndex) in item.titimgs"
                    :key="tIndex"
                  >
                    <a-image v-if="getResourceType(t) == 'image'" :src="t" />
                    <video
                      controlslist="nodownload"
                      v-else-if="getResourceType(t) == 'video'"
                      :src="t"
                      controls
                    />
                    <audio
                      controlslist="nodownload"
                      v-else-if="getResourceType(t) == 'audio'"
                      :src="t"
                      controls
                    />
                  </div>
                </template>
                <div class="answer">
                  <div class="symbol">
                    <CheckSquareFilled class="right" v-if="item.myScore > 0" />
                    <CloseSquareFilled class="wrong" v-else />
                  </div>
                  <div class="info">
                    <p>
                      <span :class="item.myScore > 0 ? 'right' : 'wrong'">
                        <b>我的得分：</b>
                        <i>{{ item.myScore }}</i>
                      </span>
                    </p>
                    <p v-if="item.analysis">
                      <b>答题解析：</b>{{ item.analysis }}
                    </p>
                  </div>
                </div>
              </template>
              <template v-else-if="path.name === '问答题'">
                <p class="title">{{ item.title }}</p>
                <template v-if="item.titimgs && item.titimgs.length">
                  <div
                    class="tit-media-wrap"
                    v-for="(t, tIndex) in item.titimgs"
                    :key="tIndex"
                  >
                    <a-image v-if="getResourceType(t) == 'image'" :src="t" />
                    <video
                      controlslist="nodownload"
                      v-else-if="getResourceType(t) == 'video'"
                      :src="t"
                      controls
                    />
                    <audio
                      controlslist="nodownload"
                      v-else-if="getResourceType(t) == 'audio'"
                      :src="t"
                      controls
                    />
                  </div>
                </template>
                <div class="opt-textarea">{{ item.options[0].title }}</div>
                <div
                  class="opt-pics"
                  v-viewer="{ modal: true, title: false }"
                  v-if="item.answerImg"
                >
                  <div
                    class="opt-pics-item ed"
                    v-for="(itemPic, itemPicIndex) in item.answerImg"
                    :key="itemPicIndex"
                  >
                    <img :src="itemPic" alt="picture" />
                  </div>
                </div>
                <div class="answer">
                  <div class="symbol" v-if="paperData.status != 1">
                    <CheckSquareFilled class="right" v-if="item.myScore > 0" />
                    <CloseSquareFilled class="wrong" v-else />
                  </div>
                  <div class="info">
                    <div>
                      <span :class="item.myScore > 0 ? 'right' : 'wrong'">
                        <b>我的得分：</b>
                        <i>{{ paperData.status == 1
                              ? item.answer1Arr &&
                                item.answer1Arr.length &&
                                paperData.answerReview != 1
                                ? item.myScore
                                : "--"
                              : item.myScore }}</i>
                      </span>
                    </div>
                    <div v-if="item.answer1Arr && item.answer1Arr.length">
                      <b>判分关键词</b>
                      <p
                        v-for="(keyword, keywordIndex) in item.answer1Arr"
                        :key="keywordIndex"
                      >
                        关键词{{ keywordIndex + 1 }}：{{
                          keyword.option.split("$").join("、")
                        }}
                      </p>
                    </div>
                    <div v-if="item.analysis">
                      <b>答题解析：</b>{{ item.analysis }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </template>
          <template v-if="path.data.length && path.name === '案例题'">
            <div v-for="(caseItem, caseIndex) in path.data" :Key="caseIndex">
              <div class="headline">
                <span class="classify">
                  {{ toChinesNum(index + caseIndex + 1) }}、{{ path.name }}
                </span>
                <span class="score">
                  （本大题共{{ caseItem.list.length }}小题， 总分
                  {{ caseItem.score }}分）
                </span>
              </div>
              <div class="case-box">
                <div class="case-box-head">
                  <p class="title">{{ caseItem.title }}</p>
                  <template v-if="caseItem.titimgs && caseItem.titimgs.length">
                    <div
                      class="tit-media-wrap"
                      v-for="(t, tIndex) in caseItem.titimgs"
                      :key="tIndex"
                    >
                      <a-image v-if="getResourceType(t) == 'image'" :src="t" />
                      <video
                        controlslist="nodownload"
                        v-else-if="getResourceType(t) == 'video'"
                        :src="t"
                        controls
                      />
                      <audio
                        controlslist="nodownload"
                        v-else-if="getResourceType(t) == 'audio'"
                        :src="t"
                        controls
                      />
                    </div>
                  </template>
                </div>
                <div
                  class="questions-item"
                  v-for="(item2, queIndex2) in caseItem.list"
                  :key="item2.questionId"
                  :id="'que_' + item2.questionId"
                >
                  <div class="questions-item-head">
                    <div class="type-title">
                      {{ queIndex2 + 1 }}.{{
                        questionTypeEnum[item2.questionType]
                      }}
                      <span>（{{ item2.score }}分）</span>
                    </div>
                  </div>
                  <template
                    v-if="item2.questionType === 1 || item2.questionType === 3"
                  >
                    <p class="title">{{ item2.title }}</p>
                    <template v-if="item2.titimgs && item2.titimgs.length">
                      <div
                        class="tit-media-wrap"
                        v-for="(t, tIndex) in item2.titimgs"
                        :key="tIndex"
                      >
                        <a-image
                          v-if="getResourceType(t) == 'image'"
                          :src="t"
                        />
                        <video
                          controlslist="nodownload"
                          v-else-if="getResourceType(t) == 'video'"
                          :src="t"
                          controls
                        />
                        <audio
                          controlslist="nodownload"
                          v-else-if="getResourceType(t) == 'audio'"
                          :src="t"
                          controls
                        />
                      </div>
                    </template>
                    <div
                      class="opt-radio"
                      :class="{
                        active: opt.isRight,
                        right: item2.answers.includes(opt.id),
                      }"
                      v-for="(opt, optIndex) in item2.options"
                      :key="opt.id"
                    >
                      <div class="opt-item">
                        <span class="letter">{{ letterOpts[optIndex] }}</span>
                        <span class="text">{{ opt.title }}</span>
                      </div>
                      <div class="opt-media-wrap" v-if="opt.img">
                        <a-image
                          v-if="getResourceType(opt.img) == 'image'"
                          :src="opt.img"
                        />
                        <video
                          controlslist="nodownload"
                          v-else-if="getResourceType(opt.img) == 'video'"
                          :src="opt.img"
                          controls
                        />
                        <audio
                          controlslist="nodownload"
                          v-else-if="getResourceType(opt.img) == 'audio'"
                          :src="opt.img"
                          controls
                        />
                      </div>
                    </div>
                    <div class="answer">
                      <div class="symbol">
                        <CheckSquareFilled
                          class="right"
                          v-if="item2.myScore > 0"
                        />
                        <CloseSquareFilled class="wrong" v-else />
                      </div>
                      <div class="info">
                        <p>
                          <span class="right" style="padding-right: 10px">
                            <b>正确答案：</b>
                            <i>{{ letterOpts[item2.correctAnswer] }}</i>
                          </span>
                          <span
                            :class="item2.myScore > 0 ? 'right' : 'wrong'"
                            style="padding-right: 10px"
                          >
                            <b>我的答案：</b>
                            <i>{{ letterOpts[item2.userAnswer] }}</i>
                          </span>
                          <span :class="item2.myScore > 0 ? 'right' : 'wrong'">
                            <b>我的得分：</b>
                            <i>{{ item2.myScore }}</i>
                          </span>
                        </p>
                        <p v-if="item2.analysis">
                          <b>答题解析：</b>{{ item2.analysis }}
                        </p>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="item2.questionType === 2">
                    <p class="title">{{ item2.title }}</p>
                    <template v-if="item2.titimgs && item2.titimgs.length">
                      <div
                        class="tit-media-wrap"
                        v-for="(t, tIndex) in item2.titimgs"
                        :key="tIndex"
                      >
                        <a-image
                          v-if="getResourceType(t) == 'image'"
                          :src="t"
                        />
                        <video
                          controlslist="nodownload"
                          v-else-if="getResourceType(t) == 'video'"
                          :src="t"
                          controls
                        />
                        <audio
                          controlslist="nodownload"
                          v-else-if="getResourceType(t) == 'audio'"
                          :src="t"
                          controls
                        />
                      </div>
                    </template>
                    <div>
                      <div
                        class="opt-checkbox"
                        :class="{
                          active: opt.isRight,
                          right: item2.answers.includes(opt.id),
                        }"
                        v-for="(opt, optIndex) in item2.options"
                        :key="opt.id"
                      >
                        <div class="opt-item">
                          <span
                            class="letter"
                            :class="{
                              wrong:
                                !opt.isRight && item2.answers.includes(opt.id),
                            }"
                          >
                            {{ letterOpts[optIndex] }}
                          </span>
                          <span
                            class="text"
                            :class="{
                              wrong:
                                opt.isRight && !item2.answers.includes(opt.id),
                            }"
                          >
                            {{ opt.title }}
                          </span>
                        </div>
                        <div class="opt-media-wrap" v-if="opt.img">
                          <a-image
                            v-if="getResourceType(opt.img) == 'image'"
                            :src="opt.img"
                          />
                          <video
                            controlslist="nodownload"
                            v-else-if="getResourceType(opt.img) == 'video'"
                            :src="opt.img"
                            controls
                          />
                          <audio
                            controlslist="nodownload"
                            v-else-if="getResourceType(opt.img) == 'audio'"
                            :src="opt.img"
                            controls
                          />
                        </div>
                      </div>
                    </div>
                    <div class="answer">
                      <div class="symbol">
                        <CheckSquareFilled
                          class="right"
                          v-if="item2.myScore > 0"
                        />
                        <CloseSquareFilled class="wrong" v-else />
                      </div>
                      <div class="info">
                        <p>
                          <span class="right" style="padding-right: 10px">
                            <b>正确答案：</b>
                            <i v-for="sele in item2.correctAnswer" :key="sele">
                              {{ letterOpts[sele] }}
                            </i>
                          </span>
                          <span
                            :class="item2.myScore > 0 ? 'right' : 'wrong'"
                            style="padding-right: 10px"
                          >
                            <b>我的答案：</b>
                            <i v-for="sele in item2.userAnswer" :key="sele">
                              {{ letterOpts[sele] }}
                            </i>
                          </span>
                          <span :class="item2.myScore > 0 ? 'right' : 'wrong'">
                            <b>我的得分：</b>
                            <i>{{ item2.myScore }}</i>
                          </span>
                        </p>
                        <p v-if="item2.analysis">
                          <b>答题解析：</b>{{ item2.analysis }}
                        </p>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="item2.questionType === 4">
                    <p class="title">
                      <template
                        v-for="(opt, optIndex) in item2.options"
                        :key="opt.id"
                      >
                        <span>{{ item2.titles[optIndex] || "、" }}</span>
                        <span class="opt-input">
                          {{ opt.title }}
                          <b v-if="item2.strAnswer">
                            <span>【</span>
                            <i
                              v-for="(text, textIndex) in JSON.parse(
                                item2.strAnswer
                              )[optIndex].split('$')"
                              :key="textIndex"
                            >
                              {{ text }}
                            </i>
                            <span>】</span>
                          </b>
                        </span>
                      </template>
                      <span v-if="item2.titles.length > item2.options.length">
                        {{ item2.titles[item2.titles.length - 1] }}
                      </span>
                    </p>
                    <template v-if="item2.titimgs && item2.titimgs.length">
                      <div
                        class="tit-media-wrap"
                        v-for="(t, tIndex) in item2.titimgs"
                        :key="tIndex"
                      >
                        <a-image
                          v-if="getResourceType(t) == 'image'"
                          :src="t"
                        />
                        <video
                          controlslist="nodownload"
                          v-else-if="getResourceType(t) == 'video'"
                          :src="t"
                          controls
                        />
                        <audio
                          controlslist="nodownload"
                          v-else-if="getResourceType(t) == 'audio'"
                          :src="t"
                          controls
                        />
                      </div>
                    </template>
                    <div class="answer">
                      <div class="symbol">
                        <CheckSquareFilled
                          class="right"
                          v-if="item2.myScore > 0"
                        />
                        <CloseSquareFilled class="wrong" v-else />
                      </div>
                      <div class="info">
                        <p>
                          <span :class="item2.myScore > 0 ? 'right' : 'wrong'">
                            <b>我的得分：</b>
                            <i>{{ item2.myScore }}</i>
                          </span>
                        </p>
                        <p v-if="item2.analysis">
                          <b>答题解析：</b>{{ item2.analysis }}
                        </p>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="item2.questionType === 5">
                    <p class="title">{{ item2.title }}</p>
                    <template v-if="item2.titimgs && item2.titimgs.length">
                      <div
                        class="tit-media-wrap"
                        v-for="(t, tIndex) in item2.titimgs"
                        :key="tIndex"
                      >
                        <a-image
                          v-if="getResourceType(t) == 'image'"
                          :src="t"
                        />
                        <video
                          controlslist="nodownload"
                          v-else-if="getResourceType(t) == 'video'"
                          :src="t"
                          controls
                        />
                        <audio
                          controlslist="nodownload"
                          v-else-if="getResourceType(t) == 'audio'"
                          :src="t"
                          controls
                        />
                      </div>
                    </template>
                    <div class="opt-textarea">{{ item2.options[0].title }}</div>
                    <div
                      class="opt-pics"
                      v-viewer="{ modal: true, title: false }"
                      v-if="item2.answerImg"
                    >
                      <div
                        class="opt-pics-item ed"
                        v-for="(itemPic, itemPicIndex) in item2.answerImg"
                        :key="itemPicIndex"
                      >
                        <img :src="itemPic" alt="picture" />
                      </div>
                    </div>
                    <div class="answer">
                      <div class="symbol" v-if="paperData.status != 1">
                        <CheckSquareFilled
                          class="right"
                          v-if="item2.myScore > 0"
                        />
                        <CloseSquareFilled class="wrong" v-else />
                      </div>
                      <div class="info">
                        <div>
                          <span :class="item2.myScore > 0 ? 'right' : 'wrong'">
                            <b>我的得分：</b>
                            <i>{{
                              paperData.status == 1
                                  ? item2.answer1Arr &&
                                    item2.answer1Arr.length &&
                                    paperData.answerReview != 1
                                    ? item2.myScore
                                    : "--"
                                  : item2.myScore
                            }}</i>
                          </span>
                        </div>
                        <div v-if="item2.answer1Arr && item2.answer1Arr.length">
                          <b>判分关键词</b>
                          <p
                            v-for="(keyword, keywordIndex) in item2.answer1Arr"
                            :key="keywordIndex"
                          >
                            关键词{{ keywordIndex + 1 }}：{{
                              keyword.option.split("$").join("、")
                            }}
                          </p>
                        </div>
                        <div v-if="item2.analysis">
                          <b>答题解析：</b>{{ item2.analysis }}
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="sidebar">
        <div class="sidebar-content">
          <div class="sheet">
            <h5>答题卡</h5>
            <div class="label">
              <div class="item"><span></span>答对</div>
              <div class="item"><span></span>答错</div>
              <div class="item"><span></span>未答</div>
            </div>
            <div class="sheet-list">
              <div
                class="type-path"
                v-for="(path, index) in quesObj.values()"
                :key="index"
              >
                <template v-if="path.data.length && path.name !== '案例题'">
                  <div class="classify">{{ path.name }}</div>
                  <div class="list clearfix">
                    <template v-if="path.name === '单选题'">
                      <div
                        class="item"
                        v-for="(item, queIndex) in path.data"
                        :key="item.questionId"
                        :class="{
                          right:
                            item.userAnswer > -1 !== '' && item.myScore > 0,
                          wrong: item.userAnswer > -1 && item.myScore == 0,
                        }"
                        @click="scrollTo(item.questionId)"
                      >
                        {{ queIndex + 1 }}
                      </div>
                    </template>
                    <template v-else-if="path.name === '多选题'">
                      <div
                        class="item"
                        v-for="(item, queIndex) in path.data"
                        :key="item.questionId"
                        :class="{
                          right: item.userAnswer.length && item.myScore > 0,
                          wrong: item.userAnswer.length && item.myScore == 0,
                        }"
                        @click="scrollTo(item.questionId)"
                      >
                        {{ queIndex + 1 }}
                      </div>
                    </template>
                    <template v-else-if="path.name === '判断题'">
                      <div
                        class="item"
                        v-for="(item, queIndex) in path.data"
                        :key="item.questionId"
                        :class="{
                          right:
                            item.userAnswer > -1 !== '' && item.myScore > 0,
                          wrong: item.userAnswer > -1 && item.myScore == 0,
                        }"
                        @click="scrollTo(item.questionId)"
                      >
                        {{ queIndex + 1 }}
                      </div>
                    </template>
                    <template v-else-if="path.name === '填空题'">
                      <div
                        class="item"
                        v-for="(item, queIndex) in path.data"
                        :key="item.questionId"
                        :class="{
                          right: item.userAnswer.length && item.myScore > 0,
                          wrong: item.userAnswer.length && item.myScore == 0,
                        }"
                        @click="scrollTo(item.questionId)"
                      >
                        {{ queIndex + 1 }}
                      </div>
                    </template>
                    <template v-else-if="path.name === '问答题'">
                      <div
                        class="item"
                        v-for="(item, queIndex) in path.data"
                        :key="item.questionId"
                        :class="{
                          right:
                            item.userAnswer.length ||
                            item.answerImg.length ||
                            item.myScore > 0,
                          wrong:
                            (item.userAnswer.length || item.answerImg.length) &&
                            item.myScore == 0,
                        }"
                        @click="scrollTo(item.questionId)"
                      >
                        {{ queIndex + 1 }}
                      </div>
                    </template>
                  </div>
                </template>
                <template v-if="path.data.length && path.name === '案例题'">
                  <div
                    v-for="(caseItem, caseIndex) in path.data"
                    :Key="caseIndex"
                  >
                    <div class="classify">
                      {{ toChinesNum(index + caseIndex + 1) }}、{{ path.name }}
                    </div>
                    <div class="list clearfix">
                      <template
                        v-for="(item, queIndex) in caseItem.list"
                        :key="item.questionId"
                      >
                        <div
                          class="item"
                          v-if="item.questionType === 1"
                          :class="{
                            right:
                              item.userAnswer > -1 !== '' && item.myScore > 0,
                            wrong: item.userAnswer > -1 && item.myScore == 0,
                          }"
                          @click="scrollTo(item.questionId)"
                        >
                          {{ queIndex + 1 }}
                        </div>
                        <div
                          class="item"
                          v-if="item.questionType === 2"
                          :class="{
                            right: item.userAnswer.length && item.myScore > 0,
                            wrong: item.userAnswer.length && item.myScore == 0,
                          }"
                          @click="scrollTo(item.questionId)"
                        >
                          {{ queIndex + 1 }}
                        </div>
                        <div
                          class="item"
                          v-if="item.questionType === 3"
                          :class="{
                            right:
                              item.userAnswer > -1 !== '' && item.myScore > 0,
                            wrong: item.userAnswer > -1 && item.myScore == 0,
                          }"
                          @click="scrollTo(item.questionId)"
                        >
                          {{ queIndex + 1 }}
                        </div>
                        <div
                          class="item"
                          v-if="item.questionType === 4"
                          :class="{
                            right: item.userAnswer.length && item.myScore > 0,
                            wrong: item.userAnswer.length && item.myScore == 0,
                          }"
                          @click="scrollTo(item.questionId)"
                        >
                          {{ queIndex + 1 }}
                        </div>
                        <div
                          class="item"
                          v-if="item.questionType === 5"
                          :class="{
                            right:
                              item.userAnswer.length ||
                              item.answerImg.length ||
                              item.myScore > 0,
                            wrong:
                              (item.userAnswer.length ||
                                item.answerImg.length) &&
                              item.myScore == 0,
                          }"
                          @click="scrollTo(item.questionId)"
                        >
                          {{ queIndex + 1 }}
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="btn">
              <a-button
                type="primary"
                block
                v-if="
                  examInfo.status != 1 &&
                  examInfo.startTime <= currentTime &&
                  examInfo.endTime >= currentTime &&
                  (examInfo.examTimes == -2 ||
                    (examInfo.examTimes == -1 && examInfo.myTimes <= 0) ||
                    (examInfo.examTimes > 0 &&
                      examInfo.myTimes < examInfo.examTimes))
                "
                @click="jump"
                >再考一次</a-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { questionTypeEnum, letterOpts } from "@/utils/business";
import { formatSeconds, toChinesNum } from "@/utils/tools";
import { getTaskDetail } from "@/api/project";
import { examResult } from "@/api/exam";

export default {
  setup() {
    const route = useRoute();
    const queryData = route.query;
    const qusetionTotal = ref(0);
    const taskId = parseInt(queryData.taskId || 0),
      detailId = parseInt(queryData.detailId || 0),
      paperId = parseInt(queryData.paperId || 0),
      submitId = parseInt(queryData.submitId || 0),
      totalScore = parseInt(queryData.totalScore || 0),
      limitTime = parseInt(queryData.limitTime || 0);

    // 试卷详情
    const examInfo = ref({
        totalScore: 0,
        passScore: 1,
      }),
      currentTime = Date.parse(new Date()) / 1000;
    let openFace = false;
    getTaskDetail({ id: taskId, did: detailId }).then((res) => {
      if (res.ret === 0) {
        examInfo.value = res.data.exam;
        openFace =
          res.data.openFace == 1 || res.data.openFace == 3 ? true : false;
      }
    });

    // 获取数据
    const paperData = ref({
      score: 0,
    });
    examResult({
      taskId: taskId,
      detailId: detailId,
      paperId: paperId,
      submitId: submitId,
    }).then((res) => {
      if (res.ret === 0) {
        paperData.value = res.data;
        renderQues(res.data.questions);
      }
    });

    // 数据处理
    const quesObj = ref(new Map());
    const rightNum = ref(0);
    Object.keys(questionTypeEnum).forEach((key) => {
      quesObj.value.set(Number(key), {
        name: questionTypeEnum[key],
        data: [],
      });
    });
    const renderQues = (list) => {
      list.forEach((item, index) => {
        if (item.questionType === 1 || item.questionType === 3) {
          if (item.myScore > 0) rightNum.value++;
          qusetionTotal.value++;
          for (let i = 0; i < item.options.length; i++) {
            if (item.options[i].id == item.answers[0]) item.correctAnswer = i;
            if (item.options[i].isRight) item.userAnswer = i;
          }
        } else if (item.questionType === 2) {
          if (item.myScore > 0) rightNum.value++;
          qusetionTotal.value++;
          item.userAnswer = [];
          item.correctAnswer = [];
          for (let i = 0; i < item.options.length; i++) {
            for (let j = 0; j < item.answers.length; j++) {
              if (item.options[i].id == item.answers[j])
                item.correctAnswer.push(i);
            }
            if (item.options[i].isRight) item.userAnswer.push(i);
          }
        } else if (item.questionType === 4) {
          if (item.myScore > 0) rightNum.value++;
          qusetionTotal.value++;
          item.userAnswer = item.answers || "";
          item.titles = item.title.split("＿");
          item.options = item.options || [];
          if (!item.options.length) {
            for (var i = 0; i < item.titles.length - 1; i++) {
              item.options.push({ id: i, title: "", isRight: false });
            }
          }
        } else if (item.questionType === 5) {
          if (item.myScore > 0) rightNum.value++;
          qusetionTotal.value++;
          item.userAnswer = item.answers;
          item.options = item.options || [];
          if (!item.options.length) {
            item.options = [{ id: 0, title: "", isRight: false }];
          }
          if (item.questionAnswers && item.questionAnswers != "[]") {
            item.answer1Arr = JSON.parse(item.questionAnswers);
          }
        } else if (item.questionType === 8) {
          caseRender(item.list);
        }
        quesObj.value.get(item.questionType).data.push(item);
      });
      quesObj.value.forEach(function (value, key) {
        value.totalScore = 0;
        //   quesObj.value.get(key - 1).data.length;
        for (let i = 0; i < value.data.length; i++) {
          value.totalScore += value.data[i].score;
        }
      });
      // 删除没有题目题型的map
      Object.keys(questionTypeEnum).forEach((key) => {
        if (quesObj.value.get(Number(key)).data.length == 0) {
          quesObj.value.delete(Number(key));
        }
      });
    };

    const caseRender = (list) => {
      list.forEach((item, index) => {
        if (item.myScore > 0) rightNum.value++;
        item.answers = item.answers || [];
        item.options = item.options || [];
        if (item.questionType === 1 || item.questionType === 3) {
          qusetionTotal.value++;
          for (let i = 0; i < item.options.length; i++) {
            if (item.options[i].id == item.answers[0]) item.correctAnswer = i;
            if (item.options[i].isRight) item.userAnswer = i;
          }
        } else if (item.questionType === 2) {
          qusetionTotal.value++;
          item.userAnswer = [];
          item.correctAnswer = [];
          for (let i = 0; i < item.options.length; i++) {
            for (let j = 0; j < item.answers.length; j++) {
              if (item.options[i].id == item.answers[j])
                item.correctAnswer.push(i);
            }
            if (item.options[i].isRight) item.userAnswer.push(i);
          }
        } else if (item.questionType === 4) {
          qusetionTotal.value++;
          item.userAnswer = item.answers || "";
          item.titles = item.title.split("＿");
          item.options = item.options || [];
          if (!item.options.length) {
            for (var i = 0; i < item.titles.length - 1; i++) {
              item.options.push({ id: i, title: "", isRight: false });
            }
          }
        } else if (item.questionType === 5) {
          qusetionTotal.value++;
          item.userAnswer = item.answers;
          item.options = item.options || [];
          if (!item.options.length) {
            item.options = [{ id: 0, title: "", isRight: false }];
          }
          if (item.questionAnswers && item.questionAnswers != "[]") {
            item.answer1Arr = JSON.parse(item.questionAnswers);
          }
        }
      });
    };

    // 答题卡定位
    const scrollTo = (id) => {
      document.getElementById("que_" + id).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    };

    const jump = () => {
      let url = `/exam/paper?taskId=${taskId}&detailId=${detailId}&openFace=${
        openFace ? "10" : examInfo.value.openFace
      }&switchNum=${examInfo.value.switchNum}`;
      // window.open(
      //   url,
      //   "考试答题",
      //   `width=${screen.availWidth},height=${
      //     screen.availHeight - 30
      //   },top=0,left=0`
      // );
      window.location.href = url;
      // router.push({
      //   path: "/exam/paper",
      //   query: {
      //     taskId: taskId,
      //     detailId: detailId,
      //     totalScore: totalScore,
      //     limitTime: limitTime,
      //   },
      // });
    };

    const getResourceType = (url) => {
      if (!url || url == "") {
        return "";
      }
      const imgFormat = ["jpg", "png", "gif"];
      const videoFormat = ["mp4"];
      const audioFormat = ["mp3"];
      let u = url.split(".");
      let suffix = u[u.length - 1].toLowerCase();
      let type = "";
      if (imgFormat.indexOf(suffix) >= 0) {
        type = "image";
      } else if (videoFormat.indexOf(suffix) >= 0) {
        type = "video";
      } else if (audioFormat.indexOf(suffix) >= 0) {
        type = "audio";
      }
      return type;
    };

    return {
      questionTypeEnum,
      qusetionTotal,
      letterOpts,
      toChinesNum,
      formatSeconds,
      examInfo,
      currentTime,
      paperData,
      quesObj,
      rightNum,
      scrollTo,
      jump,
      getResourceType,
    };
  },
};
</script>

<style lang="less" scoped>
.head {
  text-align: center;
  padding: 36px 0;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(228, 228, 228, 0.5) inset;
  h1 {
    color: #333;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 16px;
    .mixinEllipsis(50px);
  }
  &-info {
    .mixinFlex(center);
    .signature {
      width: 150px;
      height: 70px;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .info {
    line-height: 70px;
    color: #555;
    font-size: 14px;
    margin: 0;
    padding: 2px 0;
    span {
      padding: 0 12px;
      border-right: 1px solid #bbbbbb;
      &:last-child {
        border-right: none;
      }
    }
  }
}
.statistics {
  margin-top: 24px;
  .content {
    .mixinWrap();
    .mixinFlex(space-between; center);
    background-color: #fff;
    padding: 25px 0;
    .item {
      width: 20%;
      .mixinFlex(center; center; column);
      .num {
        height: 52px;
        margin: 8px 0;
        strong {
          font-size: 46px;
          line-height: 52px;
        }
        i {
          font-style: normal;
          color: #999999;
          font-size: 20px;
          margin-left: 8px;
          vertical-align: bottom;
        }
      }
      .label {
        margin-top: 2px;
        font-size: 18px;
        color: #202020;
      }
      .progress {
        .mixinFlex(center; center; column);
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: #266fff;
        .progress-info {
          .mixinFlex(center; center; column);
          .symbol {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #52abff;
            color: #0c4fd3;
            font-size: 12px;
            .mixinFlex(center; center);
          }
          .score {
            color: #fff;
            font-size: 14px;
            margin: 10px 0;
            strong {
              font-size: 42px;
              line-height: 48px;
              font-weight: normal;
              padding-right: 2px;
            }
          }
          .text {
            color: #fff;
            font-size: 16px;
          }
        }
        &.fail {
          background-color: #e13821;
          .symbol {
            background-color: #f16e6e;
            color: #a60c0c;
          }
        }
        &.loadding {
          background-color: #00b319;
          .symbol {
            background-color: #64d869;
            color: #006d05;
          }
          .score {
            strong {
              font-size: 32px;
            }
          }
        }
      }
      &:nth-child(2) {
        border-right: 1px solid #eeeeee;
        strong {
          color: #266fff;
        }
      }
      &:nth-child(3) {
        border-right: 1px solid #eeeeee;
        strong {
          color: #f65464;
        }
      }
      &:nth-child(4) {
        border-right: 1px solid #eeeeee;
        strong {
          color: #00aa18;
        }
      }
      &:nth-child(5) {
        strong {
          color: #ff6426;
          ::v-deep(span) {
            font-style: normal;
            color: #999999;
            font-size: 20px;
            margin-left: 8px;
            vertical-align: baseline;
          }
        }
      }
    }
  }
}
.main {
  background-color: @color-page-light;
  padding-bottom: 80px;
  .content {
    .mixinWrap();
    .mixinFlex(space-between);
  }
  .questions {
    width: 894px;
    .headline {
      line-height: 68px;
      color: #202020;
      .classify {
        font-size: 20px;
      }
      .score {
        font-size: 16px;
      }
    }
    &-item {
      padding: 24px;
      background-color: #fff;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      &-head {
        margin-bottom: 16px;
        .mixinFlex(space-between; center);
        .type-title {
          color: #e10808;
          font-size: 18px;
          line-height: 30px;
          span {
            color: #202020;
          }
        }
      }
      .title {
        font-size: 18px;
        line-height: 1.7;
        color: #202020;
        margin: 16px 0;
      }
      .opt-checkbox,
      .opt-radio {
        // .mixinFlex(flex-start; center);
        white-space: normal !important;
        .opt-item {
          .mixinFlex(flex-start; center);
          font-size: 16px;
          margin-bottom: 16px;
          .letter {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            .mixinFlex(center; center);
            margin-right: 20px;
            border: 1px solid #dddddd;
          }
          .text {
            color: #202020;
            line-height: 28px;
            max-width: calc(100% - 48px);
          }
        }
        &.active {
          .letter {
            border: none;
            background-color: #f65464;
            color: #fff;
          }
          .text {
            color: #f65464;
          }
        }
        &.right {
          .letter {
            border: none;
            background-color: #266fff;
            color: #fff;
          }
          .text {
            color: #266fff;
          }
        }
        .letter.wrong {
          border: none;
          background-color: #f65464;
          color: #fff;
        }
        .text.wrong {
          color: #f65464;
        }
      }
      .answer {
        background-color: #f9f9f9;
        padding: 22px 24px;
        .mixinFlex(space-between);
        .symbol {
          width: 20px;
          height: 20px;
          font-size: 22px;
          line-height: 28px;
          .right {
            color: #1ec88b;
          }
          .wrong {
            color: #ff4c50;
          }
        }
        .info {
          width: calc(100% - 34px);
          font-size: 16px;
          line-height: 28px;
          color: #333;
          & > div {
            margin-bottom: 5px;
            p {
              margin-bottom: 0;
            }
            b {
              font-weight: normal;
              color: #000;
            }
            i {
              font-style: normal;
              letter-spacing: 1px;
            }
            span {
              padding-right: 20px;
              &.right {
                i {
                  color: #266fff;
                }
              }
              &.wrong {
                i {
                  color: #f65464;
                }
              }
            }
          }
        }
      }
      .opt-input {
        border-bottom: 1px solid #202020;
        padding: 1px 4px;
        b {
          font-weight: normal;
          padding: 0 20px;
          position: relative;
          span {
            font-size: 20px;
            color: #266fff;
          }
          i {
            font-style: normal;
            color: #266fff;
            background-color: #e9effa;
            padding: 0 6px 0 3px;
            margin-right: 3px;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              right: 0;
              width: 3px;
              background-color: #fff;
            }
            &:last-child {
              padding-right: 3px;
              &::after {
                width: 0;
              }
            }
          }
        }
      }
      .opt-textarea {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 10px;
        min-height: 120px;
        margin-top: 4px;
        font-size: 16px;
        margin-bottom: 12px;
      }
      .opt-pics {
        margin-bottom: 12px;
        &-item {
          display: inline-block;
          width: 105px;
          height: 105px;
          margin-right: 10px;
          border-radius: 4px;
          position: relative;
          &.ed {
            width: 105px;
            height: 105px;
            overflow: hidden;
            justify-content: center;
            align-items: center;
            font-size: 0;
            position: relative;
            user-select: none;
            display: inline-flex;
            img {
              width: 100%;
              height: 100%;
              cursor: pointer;
            }
          }
        }
      }
    }
    .case-box {
      padding: 12px;
      background-color: #fafafa;
      &-head {
        .title {
          font-size: 18px;
          line-height: 1.7;
          color: #202020;
          margin: 0 0 16px;
        }
      }
    }
  }
  .sidebar {
    width: 282px;
    padding-top: 68px;
    position: relative;
    &-content {
      position: sticky;
      top: 24px;
    }
    .sheet {
      background-color: #fff;
      padding: 20px 16px;
      h5 {
        font-size: 20px;
        color: #333;
        line-height: 30px;
        margin-bottom: 0;
        font-weight: 500;
        text-align: center;
      }
      .label {
        padding: 16px 0 20px;
        font-size: 16px;
        color: #333;
        .mixinFlex(space-around);
        .item {
          .mixinFlex(flex-start; center);
          span {
            width: 16px;
            height: 16px;
            margin-right: 4px;
            border-radius: 2px;
          }
          &:nth-child(1) {
            span {
              background-color: #447dff;
            }
          }
          &:nth-child(2) {
            span {
              background-color: #f65464;
            }
          }
          &:nth-child(3) {
            span {
              border: 1px solid #999999;
            }
          }
        }
      }
      .scrollbar();
      .sheet-list {
        max-height: 400px;
        height: calc(100vh - 250px);
        overflow-y: auto;
        .type-path {
          .classify {
            font-size: 16px;
            color: #333;
            line-height: 26px;
            margin-bottom: 8px;
          }
          .list {
            .item {
              float: left;
              width: 36px;
              height: 40px;
              overflow: hidden;
              border-radius: 2px;
              border: 1px solid #999999;
              cursor: pointer;
              margin-right: 14px;
              margin-bottom: 14px;
              color: #999999;
              font-size: 18px;
              position: relative;
              .mixinFlex(center; center);
              &:nth-child(5n) {
                margin-right: 0;
              }
              &.right {
                border-color: #447dff;
                color: #447dff;
              }
              &.wrong {
                border-color: #f65464;
                color: #f65464;
              }
            }
          }
        }
      }
      .btn {
        padding-top: 20px;
        .ant-btn {
          height: 48px;
          font-size: 16px;
          background-color: #447dff;
          border-radius: 4px;
        }
      }
    }
  }
}
.tit-media-wrap {
  width: 500px;
  padding-bottom: 16px;
  font-size: 0;
  audio,
  video {
    width: 100%;
    font-size: 0;
  }
  audio::-webkit-media-controls-enclosure {
    border-radius: 0;
  }
  ::v-deep(.ant-image) {
    width: 100%;
    max-height: 300px;
    overflow: hidden;
    display: flex;
    align-items: center;
    img {
      width: auto;
      max-width: 100%;
      cursor: pointer;
    }
  }
}
.opt-media-wrap {
  width: 300px;
  overflow: hidden;
  margin-bottom: 10px;
  audio,
  video {
    width: 100%;
    font-size: 0;
  }
  audio::-webkit-media-controls-enclosure {
    border-radius: 0;
  }
  ::v-deep(.ant-image) {
    width: 100%;
    max-height: 200px;
    overflow: hidden;
    display: flex;
    align-items: center;
    img {
      width: auto;
      max-width: 100%;
      cursor: pointer;
    }
  }
}
</style>
